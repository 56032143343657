/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { BASE_URI } from "../../config/constant";
import { ReducerStateIF } from "../../redux/reducers";
import "./FaqSidebar.css";

const ShortcutSidebar = ({ isOpen, setIsOpen }: any) => {
  const [shortcutName, setShortcutName] = useState("");
  const [shortcutQuestion, setShortcutQuestion] = useState("");
  const states = useSelector((state: ReducerStateIF) => state);

  const handleSave = () => {
    try {
      const formData: any = new FormData();
      formData.append("name", shortcutName);
      formData.append("question", shortcutQuestion);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: BASE_URI + "save_wt_short_cut_buttons/",
        headers: {
          Authorization: states.app.authToken,
          Authorization_H: states.app.authToken,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      axios.request(config).then((response) => {
        setShortcutName("");
        setShortcutQuestion("");
        setIsOpen(false);
      });
    } catch (error) {
      console.error("Error adding shortcut:", error);
    }
  };

  return (
    <SlidingPane
      className="slidingPenal_z_index"
      overlayClassName="some-custom-overlay-class"
      isOpen={isOpen}
      width="600px"
      title="Create a Shortcut Macro..."
      onRequestClose={() => {
        setIsOpen(false);
      }}
    >
      <Form>
        <Form.Group className="mb-3" controlId="shortcutName">
          <Form.Label>Shortcut Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter shortcut name"
            value={shortcutName}
            onChange={(e) => setShortcutName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="shortcutQuestion">
          <Form.Label>Shortcut Question</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter shortcut question"
            value={shortcutQuestion}
            onChange={(e) => setShortcutQuestion(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Form>
    </SlidingPane>
  );
};

export default ShortcutSidebar;
