/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faClipboardQuestion,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { BASE_URI } from "../../config/constant";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import "./FaqSidebar.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SlidingPane from "react-sliding-pane";

const FaqSidebar = () => {
  const [questionsList, setQuestionsList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  // const styles = useStyles();

  const updateFaqState = async (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchFaqList();
  }, []);

  async function fetchFaqList() {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: BASE_URI + "get_faqs_list/",
      };

      axios
        .request(config)
        .then((response) => {
          if (response?.data) {
            let splitarr = response?.data?.replaceAll("\n", "");
            let questionsplit = splitarr.split("|");
            let dummyArr = [];
            for (let section of questionsplit) {
              let [id, question, answer] = section.split(":");
              if (id && question && answer) {
                dummyArr.push({ id: id?.trim(), question, answer });
              }
            }
            setQuestionsList(dummyArr);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <div
        className={`faq_sidebar ${
          isOpen
            ? "col-5 opacity-0"
            : "border border-primary d-flex flex-column align-items-center"
        }`}
        onClick={(e: any) => updateFaqState(e)}
      >
        <div className="mb-5 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              color="white"
              size="2x"
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              color="white"
              size="1x"
            ></FontAwesomeIcon>
          )}
        </div>
        <a
          href="#"
          className={`transparentBtn new_faq_btn flex flex-column mb-10 ${
            isOpen ? "opacity-0" : "d-flex flex-column align-items-center gab-2"
          }`}
        >
          <FontAwesomeIcon icon={faClipboardQuestion}></FontAwesomeIcon>

          <div className="d-flex flex-column gab-0 fs-6">
            <span>F</span>
            <span>A</span>
            <span>Q</span>
          </div>
        </a>
      </div>

      <SlidingPane
        className="slidingPenal_z_index"
        overlayClassName="some-custom-overlay-class"
        isOpen={isOpen}
        width="600px"
        title="Frequently Asked Questions..."
        // subtitle="Optional subtitle."
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        <Accordion>
          {questionsList.map((question: any, index: string) => (
            <AccordianList key={index} index={index} data={question} />
          ))}
        </Accordion>
      </SlidingPane>
    </>
  );
};

function AccordianList({
  index = "0",
  data,
}: {
  index: string;
  data: { id: string; question: string; answer: string };
}) {
  return (
    <Accordion.Item eventKey={index.toString()}>
      <Accordion.Header className="flex flex-column">
        {index + 1}.{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "5px",
          }}
        >
          {data?.question
            ?.toString()
            ?.split("<br/>")
            .map((text, i) => (
              <div key={"x" + i}>{text}</div>
            ))}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {data?.answer
          ?.toString()
          ?.split("<br/>")
          .map((text, i) => (
            <div key={"x" + i}>{text}</div>
          ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default FaqSidebar;
