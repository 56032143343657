import { useState } from "react";
import FaqSidebar from "./FaqSidebar";
import Header from "./Header";
import ShortcutSidebar from "./ShortcutSidebar";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";

const Template = ({ children }: any) => {
  const [isShortcutOpen, setIsShortcutOpen] = useState(false);
  const location = useLocation();
  const isFaqRoute = location.pathname === "/faq";

  // useEffect(() => {
  //   setTimeout(() => {
  //     const sidebarContentEl = document.getElementById("sidebar-content");
  //     if (sidebarContentEl) {
  //       createPortal(<FaqSidebar />, sidebarContentEl);
  //     }
  //   }, 2000);
  // },[]);
  if (!isFaqRoute) {
    return (
      <div className="p-3 pe-2 d-flex min-height-100vh relative">
        <Sidebar isOpen={isShortcutOpen} setIsOpen={setIsShortcutOpen} />
        <div className="main_section">
          <Header />
          {children}
        </div>
        <FaqSidebar />
        <ShortcutSidebar
          isOpen={isShortcutOpen}
          setIsOpen={setIsShortcutOpen}
        />
      </div>
    );
  } else {
    return (
      <div className="min-height-100vh" style={{ backgroundColor: "#2666B0" }}>
        {children}
      </div>
    );
  }
};

export default Template;
