import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chat from "../screens/Chat";
import Dashboard from "../screens/Dashboard";
import ErrorPage from "../screens/ErrorPage";
import FAQ from "../screens/FAQ";
import Template from "../screens/Layout/Template";

const AppMain = () => {
  return (
    <>
      <Suspense fallback={<span>loading</span>}>
        <Template>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Template>
      </Suspense>
      <ToastContainer />
    </>
  );
};

export default AppMain;
