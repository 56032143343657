import { Col, Modal, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import profile from "../../assets/profile.png";
import answer from "../../assets/answer.png";
import "./Chat.css";
// import "../Dashboard/Dashboard.css";
import API from "../../redux/services/Api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  setChatHistoryState,
  setChatStatusType,
  setFileHistoryState,
} from "../../redux/actions/ConfigAction";
import { ReducerStateIF } from "../../redux/reducers";
import React from "react";
import * as Plot from "@observablehq/plot";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Typeahead } from "react-bootstrap-typeahead";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// const data = [{"full_name":"Sherrod Brown","birthday":"1952-11-09T00:00:00.000Z","gender":"M","type":"sen","state":"OH","party":"Democrat"},{"full_name":"Maria Cantwell","birthday":"1958-10-13T00:00:00.000Z","gender":"F","type":"sen","state":"WA","party":"Democrat"},{"full_name":"Benjamin L. Cardin","birthday":"1943-10-05T00:00:00.000Z","gender":"M","type":"sen","state":"MD","party":"Democrat"},{"full_name":"Thomas R. Carper","birthday":"1947-01-23T00:00:00.000Z","gender":"M","type":"sen","state":"DE","party":"Democrat"},{"full_name":"Robert P. Casey, Jr.","birthday":"1960-04-13T00:00:00.000Z","gender":"M","type":"sen","state":"PA","party":"Democrat"},{"full_name":"Dianne Feinstein","birthday":"1933-06-22T00:00:00.000Z","gender":"F","type":"sen","state":"CA","party":"Democrat"},{"full_name":"Amy Klobuchar","birthday":"1960-05-25T00:00:00.000Z","gender":"F","type":"sen","state":"MN","party":"Democrat"},{"full_name":"Robert Menendez","birthday":"1954-01-01T00:00:00.000Z","gender":"M","type":"sen","state":"NJ","party":"Democrat"},{"full_name":"Bernard Sanders","birthday":"1941-09-08T00:00:00.000Z","gender":"M","type":"sen","state":"VT","party":"Independent"},{"full_name":"Debbie Stabenow","birthday":"1950-04-29T00:00:00.000Z","gender":"F","type":"sen","state":"MI","party":"Democrat"},{"full_name":"Jon Tester","birthday":"1956-08-21T00:00:00.000Z","gender":"M","type":"sen","state":"MT","party":"Democrat"},{"full_name":"Sheldon Whitehouse","birthday":"1955-10-20T00:00:00.000Z","gender":"M","type":"sen","state":"RI","party":"Democrat"},{"full_name":"John Barrasso","birthday":"1952-07-21T00:00:00.000Z","gender":"M","type":"sen","state":"WY","party":"Republican"}]

const LineGraph = ({ answer }: any) => {
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    (async () => {
      // // console.log(data1[1])
      let amountCoum = answer[0].filter((word: any) =>
        word.toLowerCase().match("amount")
      );
      const getIndex = answer[0].indexOf(amountCoum[0]);
      let chartLabels: any = [];
      let chartAmount: any = [];
      let chartDesc: any = [];

      await answer[1].map((e: any) => {
        chartLabels.push(e[0]);
        chartAmount.push(Number(e[getIndex].replace(/\$/g, "")));
        chartDesc.push(e[1]);
      });

      const tmpdata = {
        labels: chartLabels,
        datasets: [
          {
            label: "Amount: ",
            description: chartDesc,
            data: chartAmount,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      };
      setData(tmpdata);
    })();
  }, []);
  return (
    <>
      {data && (
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context: any) {
                    // console.log(context.dataIndex)
                    let amountLable = context.dataset.label;
                    if (context.parsed.y !== null) {
                      amountLable += new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(context.parsed.y);
                    }
                    let label = [
                      context.dataset.description[context.dataIndex],
                      amountLable,
                    ];

                    return label;
                  },
                },
              },
            },
          }}
          data={data}
        />
      )}
    </>
  );
};

const Chat = () => {
  const todayDate = moment(new Date()).format("DD-MM-YYYY");
  const currentYearMonth = moment(new Date()).format("MM-YYYY");
  const states = useSelector((state: ReducerStateIF) => state);
  const initHistory = states.conf.chatHistory
    ? states.conf.chatHistory[currentYearMonth]
      ? states.conf.chatHistory[currentYearMonth][todayDate]
        ? states.conf.chatHistory[currentYearMonth][todayDate].length > 0
          ? states.conf.chatHistory[currentYearMonth][todayDate][
              states.conf.chatHistory[currentYearMonth][todayDate].length - 1
            ]
          : []
        : []
      : []
    : [];
  // console.log(initHistory)
  const [chatStarted, setChatStarted] = useState(
    initHistory.length > 0 ? true : false
  );

  // const [objectList, setObjectList] = useState([])
  const [fileHistory, setFileHistory] = useState<any>([]);
  const [questionList, setQuestionList] = useState([]);
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [chatLoder, setChatLoder] = useState(false);
  const dispatch: any = useDispatch();
  const navigation = useNavigate();
  const messagesEndRef: any = useRef(null);
  const [questionTextInput, setQuestionTextInput] = useState("");
  const [selected, setSelected] = useState([]);
  const [fileLoader, setFileLoader] = useState(false);
  const [questionLoader, setQuestionLoader] = useState(true);
  const [filterParam, setFilterParam] = useState({
    kTime: 0,
    kDate: todayDate,
    kYear: currentYearMonth,
  });

  const params = new URLSearchParams(window.location.search);
  const keyYear = params.get("year");
  const keyDate = params.get("date");
  const keyTime = params.get("time");

  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    if (states.conf.chatType) {
      dispatch(setChatStatusType(false));
      let stateHistory: any = states.conf.chatHistory;
      if (stateHistory[currentYearMonth]) {
        if (stateHistory[currentYearMonth][todayDate]) {
          if (
            stateHistory[currentYearMonth][todayDate].length > 0 &&
            stateHistory[currentYearMonth][todayDate][
              stateHistory[currentYearMonth][todayDate].length - 1
            ].length > 0
          ) {
            stateHistory[currentYearMonth][todayDate].push([]);
          }
        } else {
          stateHistory[currentYearMonth] = {
            ...stateHistory[currentYearMonth],
            [todayDate]: [[]],
          };
        }
      } else {
        stateHistory = {
          ...stateHistory,
          [currentYearMonth]: {
            [todayDate]: [[]],
          },
        };
      }

      dispatch(setChatHistoryState(stateHistory));
      setChatHistory([]);
      setChatStarted(false);
    }
  }, [states.conf.chatType]);

  useEffect(() => {
    if (keyYear && keyDate && keyTime) {
      setFilterParam({
        kTime: Number(keyTime),
        kDate: keyDate,
        kYear: keyYear,
      });
      setIsFilter(true);
      const getHistory = states.conf.chatHistory
        ? states.conf.chatHistory[keyYear]
          ? states.conf.chatHistory[keyYear][keyDate].length > 0
            ? states.conf.chatHistory[keyYear][keyDate][keyTime]
            : []
          : []
        : [];
      setChatHistory(getHistory);
      if (getHistory.length > 0) {
        setChatStarted(true);
      }
    } else {
      setFilterParam({
        kTime: 0,
        kDate: todayDate,
        kYear: currentYearMonth,
      });
      setChatHistory(initHistory);
      setIsFilter(false);
    }
  }, [keyYear, keyDate, keyTime]);

  // useEffect(() => {
  //     if(states.conf.chatType !== chatStarted){
  //         console.log("update")
  //         setChatStarted(states.conf.chatType)
  //     }
  // },[states.conf.chatType])

  // useEffect(() => {
  //     dispatch(setChatStatusType(chatStarted))
  // },[chatStarted])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    let stateHistory1: any = states.conf.chatHistory;
    if (isFilter) {
      if (stateHistory1[filterParam.kYear]) {
        if (stateHistory1[filterParam.kYear][filterParam.kDate]) {
          if (stateHistory1[filterParam.kYear][filterParam.kDate].length > 0) {
            stateHistory1[filterParam.kYear][filterParam.kDate][
              filterParam.kTime
            ] = chatHistory;
          } else {
            stateHistory1[filterParam.kYear][filterParam.kDate].push(
              chatHistory
            );
          }
        } else {
          stateHistory1[filterParam.kYear] = {
            ...stateHistory1[filterParam.kYear],
            [filterParam.kDate]: [chatHistory],
          };
        }
      } else {
        stateHistory1 = {
          ...stateHistory1,
          [filterParam.kYear]: {
            [filterParam.kDate]: [chatHistory],
          },
        };
      }
    } else {
      if (stateHistory1[filterParam.kYear]) {
        if (stateHistory1[filterParam.kYear][filterParam.kDate]) {
          if (stateHistory1[filterParam.kYear][filterParam.kDate].length > 0) {
            stateHistory1[filterParam.kYear][filterParam.kDate][
              stateHistory1[filterParam.kYear][filterParam.kDate].length - 1
            ] = chatHistory;
          } else {
            stateHistory1[filterParam.kYear][filterParam.kDate].push(
              chatHistory
            );
          }
        } else {
          stateHistory1[filterParam.kYear] = {
            ...stateHistory1[filterParam.kYear],
            [filterParam.kDate]: [chatHistory],
          };
        }
      } else {
        stateHistory1 = {
          ...stateHistory1,
          [filterParam.kYear]: {
            [filterParam.kDate]: [chatHistory],
          },
        };
      }
    }

    dispatch(setChatHistoryState(stateHistory1));
  }, [chatHistory]);

  useEffect(() => {
    (async () => {
      try {
        setFileLoader(true);
        const fLists: any = await API.get(
          "get_azure_processed_files",
          "",
          dispatch
        );
        if (fLists && fLists !== "") {
          const queList = fLists.split("|");
          setFileHistory(queList);
        }
        setFileLoader(false);
      } catch (error) {
        console.log(error);
        setFileLoader(false);
      }

      try {
        setQuestionLoader(true);
        const qLists: any = await API.get("get_questions_list", "", dispatch);
        if (qLists && qLists !== "") {
          const cleanedqString = qLists.replace(/'/g, '"');
          const queList = JSON.parse(cleanedqString);
          // console.log(queList)
          setQuestionList(queList);
        }
        setQuestionLoader(false);
      } catch (error) {
        console.log(error);
        setQuestionLoader(false);
      }
    })();
  }, []);

  const searchQuestion = async (questionsText: string) => {
    try {
      setChatStarted(true);
      setChatLoder(true);
      setTimeout(() => {
        scrollToBottom();
      }, 100);
      const getAndResponce: any = await API.post(
        "answer",
        questionsText,
        dispatch,
        "json"
      );
      if (getAndResponce && getAndResponce !== "") {
        const lines = getAndResponce
          .trim()
          .replaceAll("\n\n", " \n")
          .split("\n");
        const otherText = lines[0].replace("Answer : ", "");
        let isShowGraph = false;
        if (!otherText.toLowerCase().match("total")) {
          setChatHistory([
            ...chatHistory,
            {
              question: questionsText,
              otherText: otherText,
              answer: null,
            },
          ]);
        } else {
          const dataLines = lines.slice(2);
          const parsedData: any = [];

          let tableHeader = lines[1]
            .substring(3)
            .replace(/[^,|_,a-zA-Z0-9]/g, "")
            .replaceAll("_", " ")
            .trim()
            .split("|||");
          let amountCoum = tableHeader.filter((word: any) =>
            word.toLowerCase().match("amount")
          );
          let dateCoum = tableHeader.filter((word: any) =>
            word.toLowerCase().match("date")
          );
          console.log(dateCoum);
          if (dateCoum.length > 0) {
            isShowGraph = true;
          }

          let dollerSignIndex: any = [];

          amountCoum.map((title: string) => {
            const getIndex = tableHeader.indexOf(title);
            dollerSignIndex.push(getIndex);
          });

          if (
            dataLines.length === 1 &&
            dataLines[0].substring(3).trim().toLowerCase() === "no data found"
          ) {
            parsedData.push(["No Data Found"]);
          } else {
            for (let i = 0; i < dataLines.length; i++) {
              let rowData: any = [];
              let rows = dataLines[i].substring(3).trim().split("|||");
              rows.map((e: any, columnIndex: number) => {
                let textContext = e;
                if (dollerSignIndex.includes(columnIndex)) {
                  textContext = "$" + e;
                }

                rowData.push(textContext);
              });
              // console.log(rowData)
              parsedData.push(rowData);
            }
          }

          setChatHistory([
            ...chatHistory,
            {
              question: questionsText,
              otherText: otherText,
              isShowGraph: isShowGraph,
              answer: [tableHeader, parsedData],
            },
          ]);
        }
        setChatLoder(false);
      }
    } catch (error: any) {
      toast.error(error.message);
      setChatLoder(false);
    }
  };

  const searchQuestionHandler = async (e: any) => {
    e.preventDefault();
    const questionText = e.target.search.value;
    if (questionText !== "") {
      searchQuestion(questionText);
      e.target.reset();
      setQuestionTextInput("");
    }
  };

  const [chartStatus, setChartStatus] = useState<any>({});

  const viewChart = (chartIndex: number) => {
    setChartStatus((prevData: any) => ({
      ...prevData,
      ["show_" + chartIndex]: prevData["show_" + chartIndex] ? false : true,
    }));
  };

  return (
    <>
      <div className="content_section mt-3">
        <div className="mb-3">
          {!chatStarted ? (
            <>
              <div className="fileList">
                {fileLoader ? (
                  <div className="question_card">
                    <div className="chatLoder">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <Row>
                    {fileHistory.map((e: any, i: number) => (
                      <React.Fragment key={"fList_" + i}>
                        {e !== "" && (
                          <Col key={"Object_" + i} sm={6}>
                            <div
                              key={"file_" + i}
                              className="proccess_box mb-4"
                            >
                              <svg
                                width="49"
                                height="50"
                                viewBox="0 0 49 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_12_25648)">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.7624 0.5H24.8043L36.7054 12.9048V37.0322C36.7054 40.0547 34.2601 42.5 31.2481 42.5H10.7624C7.73989 42.5 5.29462 40.0547 5.29462 37.0322V5.96775C5.29456 2.94527 7.73983 0.5 10.7624 0.5Z"
                                    fill="#E5252A"
                                  />
                                  <path
                                    opacity="0.302"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M24.7938 0.5V12.8104H36.7054L24.7938 0.5Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M11.371 31.8373V24.1656H14.6349C15.443 24.1656 16.0832 24.386 16.566 24.8373C17.0487 25.2781 17.2901 25.8763 17.2901 26.6214C17.2901 27.3665 17.0487 27.9648 16.566 28.4055C16.0832 28.8568 15.443 29.0772 14.6349 29.0772H13.3336V31.8373H11.371ZM13.3336 27.4086H14.4145C14.7084 27.4086 14.9392 27.3456 15.0967 27.1987C15.2541 27.0623 15.3381 26.8733 15.3381 26.6215C15.3381 26.3696 15.2541 26.1807 15.0967 26.0443C14.9393 25.8973 14.7084 25.8344 14.4145 25.8344H13.3336V27.4086ZM18.0982 31.8373V24.1656H20.8163C21.3515 24.1656 21.8553 24.2391 22.3275 24.3965C22.7998 24.5539 23.2301 24.7744 23.6079 25.0787C23.9857 25.3725 24.2901 25.7713 24.5104 26.2751C24.7203 26.7789 24.8358 27.3561 24.8358 28.0067C24.8358 28.6469 24.7204 29.2241 24.5104 29.7279C24.2901 30.2316 23.9857 30.6304 23.6079 30.9242C23.23 31.2286 22.7998 31.449 22.3275 31.6064C21.8553 31.7638 21.3515 31.8373 20.8163 31.8373H18.0982ZM20.0187 30.1687H20.5854C20.8897 30.1687 21.1731 30.1372 21.4355 30.0637C21.6873 29.9903 21.9287 29.8748 22.1596 29.7174C22.38 29.56 22.5584 29.3396 22.6844 29.0457C22.8103 28.7519 22.8733 28.4055 22.8733 28.0067C22.8733 27.5974 22.8103 27.2511 22.6844 26.9573C22.5584 26.6635 22.38 26.4431 22.1596 26.2856C21.9287 26.1282 21.6874 26.0127 21.4355 25.9393C21.1731 25.8659 20.8897 25.8343 20.5854 25.8343H20.0187V30.1687ZM25.8223 31.8373V24.1656H31.2796V25.8343H27.7848V27.0622H30.5764V28.7204H27.7848V31.8373H25.8223Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_12_25648">
                                    <rect
                                      width="42"
                                      height="42"
                                      fill="white"
                                      transform="translate(0 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>

                              <div className="process_section flex-1">
                                <div className="file_details align-item-center d-flex flex-1">
                                  <h5 className="flex-1">{e}</h5>

                                  {/* {(fileHistory[e].status === "success") && ( */}
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.9418 22.939C18.0154 22.939 22.9391 18.0153 22.9391 11.9417C22.9391 5.86809 18.0154 0.944458 11.9418 0.944458C5.86822 0.944458 0.94458 5.86809 0.94458 11.9417C0.94458 18.0153 5.86822 22.939 11.9418 22.939Z"
                                      fill="#11BF85"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.55453 11.168C7.14953 10.8975 6.60852 10.959 6.27352 11.313C5.93902 11.6665 5.90752 12.2105 6.20002 12.6L9.20002 16.6C9.38102 16.841 9.66102 16.988 9.96252 16.9995C10.2635 17.0105 10.554 16.8855 10.7525 16.6585L17.7525 8.65847C18.098 8.26397 18.0795 7.66947 17.7105 7.29647C17.3415 6.92347 16.7475 6.89947 16.349 7.24047L9.92452 12.7475L7.55453 11.168Z"
                                      fill="#FAFAFA"
                                    />
                                  </svg>
                                  {/* )} */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        )}
                      </React.Fragment>
                    ))}
                  </Row>
                )}
              </div>
              <div className="choose_question mt-5">
                <h5>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0229 16.6667H3.31453C2.39786 16.6667 1.58955 16.1917 1.14788 15.3917C0.706216 14.5917 0.73123 13.65 1.21456 12.875L3.52286 9.18337C3.7062 8.89171 3.7062 8.35004 3.52286 8.06671L1.21456 4.37504C0.73123 3.60004 0.706216 2.65837 1.14788 1.85837C1.58955 1.05837 2.4062 0.583374 3.31453 0.583374H12.8479C13.6312 0.583374 14.5395 1.08337 14.9479 1.75004L18.4312 7.31671C18.8979 8.06671 18.8562 9.21671 18.3229 9.92504L14.0062 15.675C13.5812 16.2417 12.7312 16.6667 12.0229 16.6667ZM3.31453 1.83337C2.86453 1.83337 2.45621 2.06671 2.23955 2.46671C2.02288 2.86671 2.0312 3.33337 2.27286 3.71671L4.58121 7.40837C5.01455 8.10837 5.01455 9.15837 4.58121 9.85837L2.27286 13.55C2.0312 13.9334 2.02288 14.4 2.23955 14.8C2.45621 15.2 2.86453 15.4334 3.31453 15.4334H12.0229C12.3395 15.4334 12.8145 15.1917 13.0062 14.9417L17.3229 9.19171C17.5479 8.89171 17.5729 8.31671 17.3729 8.00004L13.8895 2.43337C13.7062 2.13337 13.1979 1.85837 12.8479 1.85837H3.31453V1.83337Z"
                      fill="#11BF85"
                    />
                  </svg>
                  &nbsp; Choose Questions
                </h5>
                {questionLoader ? (
                  <div className="question_card">
                    <div className="chatLoder">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div className="m-10">
                    <Typeahead
                      className="f20 flex-1 border-none"
                      onChange={(e: any) => setQuestionTextInput(e?.[0] || "")}
                      options={questionList}
                      placeholder="Select Question"
                      selected={
                        questionTextInput.length === 0
                          ? []
                          : [questionTextInput]
                      }
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {/* <p className="f20"><strong>Proccesed Files : </strong>{fileHistory.toString()}</p> */}
              <div className="static_section">
                {fileHistory.length > 0 && (
                  <div className="d-flex">
                    <p className="f20">
                      <strong>Proccesed Files : </strong>
                    </p>
                    <ul>
                      {fileHistory.map((e: string, fkey: number) => (
                        <React.Fragment key={"fileKey_" + fkey}>
                          {e !== "" && <li className="f20">{e}</li>}
                        </React.Fragment>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="d-flex align-item-center mb-3">
                  <label className="f20">
                    <strong>Questions List : &nbsp;</strong>
                  </label>
                  <Typeahead
                    className="f20 flex-1 border-none"
                    onChange={(e: any) => setQuestionTextInput(e?.[0] || "")}
                    options={questionList}
                    placeholder="Select Question"
                    selected={
                      questionTextInput.length === 0 ? [] : [questionTextInput]
                    }
                  />
                  {/* <select
                    className="form-control questionList_box f20 flex-1"
                    onChange={(e: any) => setQuestionTextInput(e.target.value)}
                  >
                    <option key={"el_selectoption"} value={""}>
                      Select Question
                    </option>
                    {questionList.map((e: string, qlList: number) => (
                      <option key={"el_" + qlList} value={e}>
                        {e}
                      </option>
                    ))}
                  </select> */}
                </div>
              </div>

              {chatHistory &&
                chatHistory.map((ee: any, chatIndex: number) => (
                  <div key={"questi" + chatIndex} className="question_card">
                    <div className="question_text">
                      <img src={profile} height={50} width={50} />
                      {ee.question}
                    </div>
                    <div className="answer_box d-flex">
                      <img src={answer} height={50} width={50} />
                      <div className="ans_table flex-1">
                        <p className="f20 mt-2">{ee.otherText ?? ""}</p>
                        {ee.answer && ee.answer !== "" && (
                          <>
                            <table>
                              <thead>
                                <tr>
                                  {ee.answer[0] &&
                                    ee.answer[0].map(
                                      (header: any, hi: number) => (
                                        <th key={header + "_" + hi}>
                                          {header}
                                        </th>
                                      )
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {ee.answer[1][0][0].trim().toLowerCase() !==
                                "no data found" ? (
                                  <>
                                    {ee.answer[1] &&
                                      ee.answer[1].map(
                                        (transaction: any, index: number) => (
                                          <tr key={index}>
                                            {transaction.map(
                                              (e: any, ind: number) => (
                                                <td
                                                  key={
                                                    "row" + index + "_" + ind
                                                  }
                                                >
                                                  {e ? e : "-"}
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        )
                                      )}
                                  </>
                                ) : (
                                  <tr key={0 + "no_found"}>
                                    <td
                                      colSpan={ee.answer[0].length}
                                      className="text-center"
                                    >
                                      {ee.answer[1][0][0].trim()}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {ee.isShowGraph &&
                              ee.answer[1][0][0].trim().toLowerCase() !==
                                "no data found" && (
                                <button
                                  type="button"
                                  className="default_theme_btn float-right"
                                  onClick={() => viewChart(chatIndex)}
                                >
                                  {chartStatus["show_" + chatIndex]
                                    ? "Hide"
                                    : "View"}{" "}
                                  Chart
                                </button>
                              )}

                            {/* <div className="graph_section" ref={(element:any) => ref.current[chatIndex] = element}></div> */}
                            {ee.isShowGraph &&
                              chartStatus["show_" + chatIndex] && (
                                <LineGraph answer={ee.answer} />
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              {chatLoder && (
                <div className="question_card mb-5">
                  <div className="chatLoder">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </>
          )}
        </div>
        <form
          className="search_question d-flex"
          onSubmit={searchQuestionHandler}
        >
          <div className="input_seaction flex-1">
            <input
              type="text"
              className="form-control"
              value={questionTextInput}
              onChange={(e: any) => setQuestionTextInput(e.target.value)}
              placeholder={"Send a message"}
              name="search"
              id="question"
              required
            />
            <button type="submit">
              <svg
                width="25"
                height="25"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0698 5.51001L6.50978 1.23001C0.759776 -1.64999 -1.60022 0.710012 1.27978 6.46001L2.14978 8.20001C2.39978 8.71001 2.39978 9.30001 2.14978 9.81001L1.27978 11.54C-1.60022 17.29 0.749775 19.65 6.50978 16.77L15.0698 12.49C18.9098 10.57 18.9098 7.43001 15.0698 5.51001ZM11.8398 9.75001H6.43977C6.02978 9.75001 5.68977 9.41001 5.68977 9.00001C5.68977 8.59001 6.02978 8.25001 6.43977 8.25001H11.8398C12.2498 8.25001 12.5898 8.59001 12.5898 9.00001C12.5898 9.41001 12.2498 9.75001 11.8398 9.75001Z"
                  fill="#2566AF"
                />
              </svg>
            </button>
          </div>
          {/* <button type="button" className="migrobutton">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.1202 9.11999C18.7302 9.11999 18.4202 9.42999 18.4202 9.81999V11.4C18.4202 14.94 15.5402 17.82 12.0002 17.82C8.46018 17.82 5.58018 14.94 5.58018 11.4V9.80999C5.58018 9.41999 5.27018 9.10999 4.88018 9.10999C4.49018 9.10999 4.18018 9.41999 4.18018 9.80999V11.39C4.18018 15.46 7.31018 18.81 11.3002 19.17V21.3C11.3002 21.69 11.6102 22 12.0002 22C12.3902 22 12.7002 21.69 12.7002 21.3V19.17C16.6802 18.82 19.8202 15.46 19.8202 11.39V9.80999C19.8102 9.42999 19.5002 9.11999 19.1202 9.11999Z" fill="white"/>
                            <path d="M12.0001 2C9.56008 2 7.58008 3.98 7.58008 6.42V11.54C7.58008 13.98 9.56008 15.96 12.0001 15.96C14.4401 15.96 16.4201 13.98 16.4201 11.54V6.42C16.4201 3.98 14.4401 2 12.0001 2ZM13.3101 8.95C13.2401 9.21 13.0101 9.38 12.7501 9.38C12.7001 9.38 12.6501 9.37 12.6001 9.36C12.2101 9.25 11.8001 9.25 11.4101 9.36C11.0901 9.45 10.7801 9.26 10.7001 8.95C10.6101 8.64 10.8001 8.32 11.1101 8.24C11.7001 8.08 12.3201 8.08 12.9101 8.24C13.2101 8.32 13.3901 8.64 13.3101 8.95ZM13.8401 7.01C13.7501 7.25 13.5301 7.39 13.2901 7.39C13.2201 7.39 13.1601 7.38 13.0901 7.36C12.3901 7.1 11.6101 7.1 10.9101 7.36C10.6101 7.47 10.2701 7.31 10.1601 7.01C10.0501 6.71 10.2101 6.37 10.5101 6.27C11.4701 5.92 12.5301 5.92 13.4901 6.27C13.7901 6.38 13.9501 6.71 13.8401 7.01Z" fill="white"/>
                        </svg>
                    </button> */}
        </form>
      </div>
    </>
  );
};

export default Chat;
